<template>
  <step-lesson-layout :title="$t('module1.part3.lesson3.title')" :title-annotation="$t('module1.part3.lesson3.titleAnnotation')">
    <template v-slot:content>
      <!-- BLOCK 1 -->
      <div class="mpr-block">
        <div class="head">
          <mpr-montant-by-niveau-revenu :niveaux-revenus="niveauxRevenus" />
        </div>
      </div>

      <div class="center-content">
        <!-- BLOCK 2 -->
        <div class="app-text-block">
          <p class="title">{{ $t('module1.part3.lesson3.block2.title') }}</p>
          <div class="mpr-check-list">
            <div class="mpr-check">
              <div class="image-wrapper">
                <img :src="require('@/assets/module1/part3/illu-people.svg')" :alt="$t('global.imageAlts.peopleIllu')">
              </div>
              <p class="paragraphe">{{ $t('module1.part3.lesson3.block2.people') }}</p>
            </div>
            <div class="mpr-check">
              <div class="image-wrapper">
                <img :src="require('@/assets/module1/part3/illu-money.svg')" :alt="$t('global.imageAlts.moneyIllu')">
              </div>
              <p class="paragraphe">{{ $t('module1.part3.lesson3.block2.money') }}</p>
            </div>
            <div class="mpr-check">
              <div class="image-wrapper">
                <img :src="require('@/assets/module1/part3/illu-pinned-home.svg')" :alt="$t('global.imageAlts.pinHomeIllu')">
              </div>
              <p class="paragraphe">{{ $t('module1.part3.lesson3.block2.pinnedHome') }}</p>
            </div>
          </div>
          <div class="modal-wrapper">
            <revenu-mpr-modal></revenu-mpr-modal>
          </div>
        </div>

        <!-- BLOCK 3 -->
        <div class="training-question-block">
          <p class="training-question-title">
            {{ $t('training.global.train') }}
          </p>
          <p class="training-question-label">
            {{ $t('module1.part3.lesson3.blockGame.title') }}
          </p>
          <div class="training-question-content">
            <quizz-activity :answers="mprColors" :questions="mprQuizzQuestions" :is-multiple="false" :show-pagination="true">
              <template v-slot:question="{ name, content, rightAnswer, showAnswers }">
                <div class="mpr-question">
                  <div class="left-col" :style="{ backgroundColor: showAnswers ? getMprColorByName(rightAnswer).colorHex : null }">
                    <img :src="content.image" :alt="name"/>
                    <div class="right-answer">
                      <p class="answer" v-show="showAnswers">
                        {{ getMprColorByName(rightAnswer).label }}
                      </p>
                      <p class="placeholder" v-show="!showAnswers">
                        {{ $t('global.textPlaceholder') }}
                      </p>
                    </div>
                  </div>
                  <div class="right-col">
                    <div class="mpr-check-list">
                      <div class="mpr-check">
                        <div class="image-wrapper">
                          <img :src="require('@/assets/module1/part3/illu-people.svg')" :alt="$t('global.imageAlts.peopleIllu')">
                        </div>
                        <p class="paragraphe">{{ content.situation }}</p>
                      </div>
                      <div class="mpr-check">
                        <div class="image-wrapper">
                          <img :src="require('@/assets/module1/part3/illu-money.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                        </div>
                        <p class="paragraphe">{{ content.money }}</p>
                      </div>
                      <div class="mpr-check">
                        <div class="image-wrapper">
                          <img :src="require('@/assets/module1/part3/illu-pinned-home.svg')" :alt="$t('global.imageAlts.pinHomeIllu')">
                        </div>
                        <p class="paragraphe">{{ content.location }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:answer="{ image, name }">
                <div class="mpr-answer">
                  <img :src="image" :alt="name"/>
                </div>
              </template>
            </quizz-activity>
          </div>
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="app-decoration-block end-block">
        <p class="decoration-block-content">
          {{ $t('training.global.partFinished') }}
        </p>
        <app-button-layout @click="goToNextStep">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
        <app-button-layout color="alternate-secondary" @click="resumeLater">
          {{ $t('global.navigation.resumeLater') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import RevenuMprModal from '@/components/training/module1/part3/RevenuMprModal'
import QuizzActivity from '@/components/training/QuizzActivity'
import useTrainingUtils from '@/utils/useTrainingUtils'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import MprMontantByNiveauRevenu from '@/components/training/MprNiveauxRevenus.vue'

export default {
  name: 'Module1Part3Lesson3',
  components: { MprMontantByNiveauRevenu, QuizzActivity, RevenuMprModal, StepLessonLayout, AppButtonLayout },
  setup () {
    const { resumeLater, goToNextStep } = useTrainingUtils()
    return { resumeLater, goToNextStep }
  },
  data () {
    return {
      niveauxRevenus: [
        {
          name: 'tresModeste',
          label: this.$t('training.mprMenages.tresModeste'),
          value: this.$t('module1.part3.lesson3.mpr.bleu.color')
        },
        {
          name: 'modeste',
          label: this.$t('training.mprMenages.modeste'),
          value: this.$t('module1.part3.lesson3.mpr.jaune.color')
        },
        {
          name: 'intermediaire',
          label: this.$t('training.mprMenages.intermediaire'),
          value: this.$t('module1.part3.lesson3.mpr.violet.color')
        },
        {
          name: 'aise',
          label: this.$t('training.mprMenages.aise'),
          value: this.$t('module1.part3.lesson3.mpr.rose.color')
        }
      ],
      mprColors: [
        {
          name: 'bleu',
          label: this.$t('module1.part3.lesson3.mpr.bleu.label'),
          color: this.$t('module1.part3.lesson3.mpr.bleu.color'),
          image: require('@/assets/module1/part3/logo-mpr-bleu.svg'),
          colorHex: '#b2b7db',
          small: true
        },
        {
          name: 'jaune',
          label: this.$t('module1.part3.lesson3.mpr.jaune.label'),
          color: this.$t('module1.part3.lesson3.mpr.jaune.color'),
          image: require('@/assets/module1/part3/logo-mpr-jaune.svg'),
          colorHex: '#f6e7a9',
          small: true
        },
        {
          name: 'violet',
          label: this.$t('module1.part3.lesson3.mpr.violet.label'),
          color: this.$t('module1.part3.lesson3.mpr.violet.color'),
          image: require('@/assets/module1/part3/logo-mpr-violet.svg'),
          colorHex: '#c597a4',
          small: true
        },
        {
          name: 'rose',
          label: this.$t('module1.part3.lesson3.mpr.rose.label'),
          color: this.$t('module1.part3.lesson3.mpr.rose.color'),
          image: require('@/assets/module1/part3/logo-mpr-rose.svg'),
          colorHex: '#f9dfd1',
          small: true
        }
      ],
      mprQuizzQuestions: [
        {
          name: 'question1',
          content: {
            image: require('@/assets/module1/part3/illu-menage-solo.svg'),
            situation: this.$t('module1.part3.lesson3.blockGame.questions.q1.situation'),
            money: this.$t('module1.part3.lesson3.blockGame.questions.q1.money'),
            location: this.$t('module1.part3.lesson3.blockGame.questions.q1.location')
          },
          rightAnswer: 'bleu'
        },
        {
          name: 'question2',
          content: {
            image: require('@/assets/module1/part3/illu-menage-old-couple.svg'),
            situation: this.$t('module1.part3.lesson3.blockGame.questions.q2.situation'),
            money: this.$t('module1.part3.lesson3.blockGame.questions.q2.money'),
            location: this.$t('module1.part3.lesson3.blockGame.questions.q3.location')
          },
          rightAnswer: 'violet'
        },
        {
          name: 'question3',
          content: {
            image: require('@/assets/module1/part3/illu-menage-big-family.svg'),
            situation: this.$t('module1.part3.lesson3.blockGame.questions.q3.situation'),
            money: this.$t('module1.part3.lesson3.blockGame.questions.q3.money'),
            location: this.$t('module1.part3.lesson3.blockGame.questions.q3.location')
          },
          rightAnswer: 'jaune'
        }
      ]
    }
  },
  methods: {
    getMprColorByName (name) {
      return this.mprColors.find(color => color.name === name)
    }
  }
}
</script>

<style lang="scss" scoped>
.mpr-block {
  margin: 0 $space-xs;
  background-color: #fff;
  border-radius: $radius;
  padding: $space-sm 0;
  .content {
    border-top: 1px solid $c-border;
    margin-top: $space-m;
    padding: $space-sm $space-ml 0 $space-ml;
    .title {
      color: $c-locked;
      font-size: $fz-xs;
      font-weight: $fw-l;
      text-transform: uppercase;
    }
    .list-travaux {
      margin-top: $space-xs;
      display: flex;
      margin-left: -$space-xs;
      margin-right: -$space-xs;
      flex-wrap: wrap;
      img {
        margin: $space-xs;
      }
    }
  }
}
.app-text-block {
  padding: $space-ml;
  .modal-wrapper {
    margin-top: $space-l;
    display: flex;
    justify-content: center;
  }
}
.mpr-check-list {
  .mpr-check + .mpr-check {
    padding-top: $space-m;
  }
  .mpr-check {
    display: flex;
    align-items: center;
    .image-wrapper {
      width: 45px;
      max-width: 100%;
      display: flex;
      justify-content: center;
      margin-right: $space-s;
    }
  }
}
.quizz-activity {
  padding: 0;
  .mpr-answer {
    padding: $space-l $space-s;
    img {
      width: 100%;
    }
  }
  .mpr-question {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .left-col {
      width: 130px;
      border-radius: $radius;
      background-color: $c-background;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: $space-l;
      img {
        padding: 0 $space-xs;
        margin-bottom: $space-s;
      }
      .right-answer {
        background-color: #fff;
        border-radius: $radius;
        font-size: $fz-xs;
        font-weight: $fw-l;
        width: 100%;
        padding: $space-s;
        text-align: center;
        .answer {
          color: $c-text-dark;
        }
        .placeholder {
          color: $c-locked;
        }
      }
    }
    .right-col {
      margin-left: $space-s;
      .mpr-check-list {
        .mpr-check + .mpr-check {
          margin-top: $space-sm;
        }
      }
    }
  }
}

@media (min-width: $bp-phone) {
  .mpr-check-list {
    .mpr-check {
      .image-wrapper {
        margin-right: $space-m;
      }
    }
  }
  .quizz-activity {
    .mpr-question {
      .right-col {
        margin-left: $space-m;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .center-content {
    margin-top: $space-l;
    display: flex;
    align-items: flex-start;
    .app-text-block {
      margin-right: $space-sm;
      padding: 0;
      margin-top: 0;
    }
    .training-question-block {
      flex-shrink: 0;
      width: 410px;
    }
  }
}
</style>
