<template>
  <app-modal theme="training">
    <template v-slot:content>
      <div class="revenu-mpr-modal-content">
        <img :src="require('@/assets/module1/part3/popup-plafonds-mpr.png')" :alt="$t('module1.part3.lesson3.block2.modalTitle')">
      </div>
    </template>
    <template v-slot:modal-button>
      <app-button-layout color="alternate-secondary" :small="true">
        {{ $t('module1.part3.lesson3.block2.modalButton') }}
      </app-button-layout>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/layout/AppModal'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'RevenuMprModal',
  components: { AppButtonLayout, AppModal }
}
</script>

<style lang="scss" scoped>
.revenu-mpr-modal-content {
  img {
    width: 100%;
    max-width: $bp-tablet;
  }
}
</style>
